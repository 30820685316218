// stylelint-disable
$fonts-weight-style: (
    thin: (weight: 100, style: normal),
    thinItalic: (weight: 100, style: italic),
    xlight: (weight: 200, style: normal),
    xlightItalic: (weight: 200, style: italic),
    light: (weight: 300, style: normal),
    lightItalic: (weight: 300, style: italic),
    standard: (weight: 400, style: normal),
    standardItalic: (weight: 400, style: italic),
    medium: (weight: 500, style: normal),
    mediumItalic: (weight: 500, style: italic),
    bold: (weight: 700, style: normal),
    boldItalic: (weight: 700, style: italic)
);
$allowed-fonts-weight-style: inspect(map-keys($fonts-weight-style));

$font-size: (
    11: 0.6875rem,
    12: 0.75rem,
    13: 0.8125rem,
    14: 0.875rem,
    16: 1rem,
    18: 1.125rem,
    20: 1.25rem,
    22: 1.375rem,
    24: 1.5rem,
    26: 1.625rem,
    27: 1.6875rem,
    28: 1.75rem,
    30: 1.875rem,
    32: 2rem,
    36: 2.25rem,
    40: 2.5rem,
    42: 2.625rem,
    48: 3rem,
    52: 3.25rem,
    64: 4rem
);
$allowed-sizes: inspect(map-keys($font-size));

$line-height: (
    11: 1.25rem,
    12: 1.25rem,
    13: 1.23076923,
    14: 1.375rem,
    16: 1.5,
    18: 1.55555556,
    20: 1.2,
    22: 1.45454545,
    24: 1.45454545,
    26: 1.38461538,
    27: 2.0625rem,
    28: 1.38461538,
    30: 2.5rem,
    32: 1.25,
    36: 2.75rem,
    40: 3rem,
    42: 2.75rem,
    48: 3.5rem,
    52: 1.23076923,
    64: 1.125
);
$allowed-heights: inspect(map-keys($line-height));

$text-transform: (
    upper: uppercase,
    lower: lowercase,
    capital: capitalize,
    none: none
);
$allowed-transforms: inspect(map-keys($text-transform));

@mixin font-select($font-weight: null, $size: null, $color: null, $transform: none, $spacing: null, $font: 'Montserrat') {
    @if ($font) {
        font-family: $font;
    }

    @if ($font-weight) {
        $font-weight-style: map-get($fonts-weight-style, $font-weight);

        @if ($font-weight-style == null) {
            @error('You tried \'#{$font-weight}\'. Please use one of these available - #{$allowed-fonts-weight-style}');
        } @else {
            font-weight: map-get($font-weight-style, weight);
            font-style: map-get($font-weight-style, style);
        }
    }

    @if ($size) {
        $fsize: map-get($font-size, $size);
        $lheight: map-get($line-height, $size);

        @if ($fsize == null) {
            @error('You tried \'#{$size}\'. Please use one of these available - #{$allowed-sizes}');
        } @else {
            font-size: $fsize;
        }

        @if ($lheight == null) {
            @error('You tried \'#{$size}\'. Please use one of these available - #{$allowed-heights}');
        } @else {
            line-height: $lheight;
        }
    }

    @if ($color) {
        color: $color;
    }

    @if ($transform) {
        $trans: map-get($text-transform, $transform);

        @if ($trans == null) {
            @error('You tried \'#{$transform}\'. Please use one of these available - #{$allowed-transforms}');
        } @else {
            text-transform: $trans;
        }
    }

    @if ($spacing) {
        @if (type-of($spacing) == 'number' and $spacing >= 0) {
            letter-spacing: #{$spacing}px;
        } @else {
            @error('You tried \'#{$spacing}\'. Please provide a non-zero number');
        }
    }
}
// stylelint-enable
